const beaconRequest = (path) => {
  const serverHost = process.env.REACT_APP_SERVER_HOST;
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;
  const url = `${serverHost}${normalizedPath}`;

  if (navigator.sendBeacon) {
    return navigator.sendBeacon(url);
  } else {
    // Do nothing if Beacon API is not supported
    return false;
  }
};

export default beaconRequest;