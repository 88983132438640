class NotePresenter {
  constructor(note) {
    this.note = note;
  }

  getUUID() {
    return this.note?.uuid;
  }

  getBody() {
    return this.note?.user_body ? this.note.user_body : this.note?.gen_body;
  }

  getSummary() {
    return this.note?.gen_summary || (this.note?.gen_body?.substring(0, 150).replace(/#/g, "") + '...');
  }

  hasInsights() {
    return Boolean(
      this.getConsiderations() || this.getInconsistencies()
    );
  }

  getConsiderations() {
    return this.note?.gen_considerations;
  }

  getInconsistencies() {
    return this.note?.gen_inconsistencies;
  }

  setUserBody(body) {
    if (!this.note) {
      throw new Error('Cannot set user body on a null note.');
    }
    this.note.user_body = body;
  }

  isDoctorNote() {
    return Boolean(
      this.note &&
      this.note.note_type_slug &&
      this.note.note_type_slug !== 'patient_instructions'
    );
  }

  isComplete() {
    return Boolean(this.note && this.note.completed && this.getBody());
  }

  isValid() {
    return Boolean(
      this.note &&
      this.isComplete() &&
      this.getBody() &&
      !this.note.problem
    );
  }

  getTitle() {
    return this.note?.gen_title || this.note?.default_title;
  }

  getNoteType() {
    return this.note?.note_type_slug;
  }

  canBeRegenerated() {
    return Boolean(this.note && this.isComplete() && this.getBody());
  }
}

export default NotePresenter;
