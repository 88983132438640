import React from 'react';
import { Alert, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const MicrophoneError = ({ type = null }) => {
	const { Text, Link, Title } = Typography;
	const { t } = useTranslation();

	const getErrorMessages = (type) => {
		switch (type) {
			case 'mic_muted':
				return {
					message: t('session_recorder.errors.mic_muted'),
					title: t('session_recorder.errors.mic_muted_title'),
					type: 'warning',
				};
			case 'silence':
				return {
					message: t('session_recorder.errors.silence'),
					title: t('session_recorder.errors.silence_title'),
					type: 'warning',
				};
			default:
				return null;
		}
	};

	const errorMessages = getErrorMessages(type);

	if (!errorMessages) return null;

	return (
		type && (
			<Alert
				style={{ textAlign: 'left', marginBottom: '20px' }}
				message={
					<Title level={5} style={{ marginTop: '0px' }} type="primary">
						{errorMessages.title}
					</Title>
				}
				description={
					<Text type="secondary">{errorMessages.message}</Text>
				}
				type={errorMessages.type}
			/>
		)
	);
};

export default MicrophoneError;
