import React, { useEffect, useState } from 'react';
import fetchQuery from '../utils/query/fetchQuery';
import { useParams } from 'react-router-dom';
import { Skeleton, Row, Col, Layout } from 'antd';
import Bugsnag from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { handleError } from '../utils/errorHandling';
import RecentQueries from '../components/query/RecentQueries';
import QueryDetailsCard from '../components/query/QueryDetailsCard';
import usePageMeta from '../hooks/usePageMeta';
import { debounce } from 'lodash';

const { Content } = Layout;

const QueryDetails = () => {
  const { uuid } = useParams();
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const { t } = useTranslation();

  const handleReloadRecentQueries = debounce(() => {
    setReloadTrigger(prev => prev + 1); // Increment the trigger to force reload
  }, 500); // Debounce by 500ms

  useEffect(() => {
    let isMounted = true;
    let pollingInterval;

    const loadQuery = async () => {
      try {
        const queryPresenter = await fetchQuery(uuid);
        if (isMounted) {
          setQuery(queryPresenter);
          if (!queryPresenter.isCompleted()) {
            pollingInterval = setTimeout(loadQuery, 1000);
          }
        }
      } catch (err) {
        if (isMounted) {
          Bugsnag.notify(new Error(`loadQuery error: ${JSON.stringify(err)}`));
          handleError(t('query_details.errors.load'));
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    loadQuery();

    return () => {
      isMounted = false;
      if (pollingInterval) {
        clearTimeout(pollingInterval);
      }
    };
  }, [uuid]);

  // Use the usePageMeta hook to set the title and og:title
  usePageMeta(query?.getQuestion?.());

  return (
    <Content className="page">
      {loading ? (
        <Skeleton active />
      ) : query ? (
        <Row gutter={[32, 32]}>
          {/* Sidebar */}
          <Col xs={24} md={8} lg={8}>
            <RecentQueries activeQueryUuid={uuid} reloadTrigger={reloadTrigger} />
          </Col>
          {/* Main Content */}
          <Col xs={24} md={16} lg={16}>
            <QueryDetailsCard query={query} onReloadRecentQueries={handleReloadRecentQueries} />
          </Col>
        </Row>
      ) : null}
    </Content>
  );
};

export default QueryDetails;
