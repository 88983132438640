import moment from 'moment-timezone';
import NotePresenter from './NotePresenter';

class SessionPresenter {
  constructor(session) {
    this.session = session;
  }

  getUUID() {
    return this.session?.uuid;
  }

  getCompletedAt() {
    return this.session?.completed_at
      ? moment.utc(this.session.completed_at).local().fromNow()
      : null;
  }

  getStartedAt() {
    return this.session?.started_at
      ? moment.utc(this.session.started_at).local().fromNow()
      : null;
  }

  // we'll continue polling session in SessionDetails, until isReady returns true
  isReady() {
    return this.mostRecentNoteIsCompleted();
  }

  isErrored() {
    if (this.isReady()) {
      return false; // If the session is ready, it can't be errored
    }

    const now = moment.utc();

    // Check reference time
    const referenceTime = this.session?.completed_at
      ? moment.utc(this.session.completed_at)
      : moment.utc(this.session?.started_at);

    if (!referenceTime.isValid()) {
      return false; // If there's no valid time, assume not errored
    }

    const diffInMinutes = now.diff(referenceTime.startOf('minute'), 'minutes'); // Normalize to the start of the minute

    // Conditions for being errored
    if (!this.session?.completed_at) {
      return diffInMinutes > 180; // More than 3 hours since started
    }

    return diffInMinutes > 30; // More than 30 minutes since completed
  }

  isClassified() {
    return this.session?.gen_patient_visit !== null;
  }

  isDemo() {
    return this.session?.demo;
  }

  getDoctorNotes() {
    return this.session?.doctor_notes?.map(note => new NotePresenter(note)) || [];
  }

  getLastDoctorNote() {
    const doctorNotes = this.getDoctorNotes();
    return doctorNotes[doctorNotes.length - 1];
  }

  getLastDoctorNoteType() {
    const note = this.getLastDoctorNote();
    return note?.getNoteType();
  }

  getPatientInstructionsNotes() {
    return this.session?.patient_instruction_notes?.map(note => new NotePresenter(note)) || [];
  }

  getLastPatientInstructionsNote() {
    const notes = this.getPatientInstructionsNotes();
    return notes[notes.length - 1];
  }

  mostRecentNoteIsCompleted() {
    const mostRecentNote = this.getLastDoctorNote();
    return mostRecentNote && mostRecentNote.isComplete();
  }

  displayNote() {
    return this.mostRecentNoteIsCompleted();
  }

  getTranscript() {
    return this.session?.gen_transcript;
  }

  getFormattedTranscript() {
    return this.session?.gen_formatted_transcript;
  }

  getTitle() {
    const note = this.getLastDoctorNote();
    const baseTitle = note?.isComplete() ? note?.getTitle() : "Generating..."
    return this.isDemo() ? `(demo) ${baseTitle}` : baseTitle;
  }

  getExcerpt(t) {
    if (this.isErrored()) {
      const uuid = this.getUUID();
      return t('sessions.errors.generate_failure', { uuid });
    } else if (!this.isReady()) {
      return ("");
    } else {
      return (this.getLastDoctorNote().getSummary());
    }
  }

  getClassification() {
    return this.session?.gen_classification;
  }

  getUserNotes() {
    return this.session?.user_notes;
  }

  hasUserNotes() {
    return Boolean(
      this.getUserNotes()
    );
  }

  displayWidgetsColumn() {
    return this.getLastDoctorNote().hasInsights();
    // TODO: uncomment when note widget is ready
    //|| this.hasUserNotes();
  }
}

export default SessionPresenter;
