import React from 'react';
import { Button, Space, Grid } from 'antd';
import BannerAlert from '../BannerAlert';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;

const WebinarAnnouncement = ({
  icon,
  mobileText,
  desktopText,
  buttonLabel,
  buttonHref,
  alertKey,
}) => {
  const screens = useBreakpoint();
  const { i18n } = useTranslation();
  
  if (i18n.language && !i18n.language.startsWith('es')) {
    return null;
  }

  return (
    <BannerAlert
      alertKey={alertKey}
      message={
        <Space align="center" style={{width: '100%', justifyContent: 'center', flexWrap: 'wrap'}}>
          {icon}
          <span style={{textAlign: 'center'}}>
            {screens.xs ? mobileText : desktopText}
          </span>
          <Button 
            type="primary" 
            size={screens.xs ? "small" : "middle"} 
            ghost 
            href={buttonHref} 
            target="_blank"
          >
            {buttonLabel}
          </Button>
        </Space>
      }
    />
  );
};

WebinarAnnouncement.propTypes = {
  icon: PropTypes.node.isRequired,
  mobileText: PropTypes.string.isRequired,
  desktopText: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonHref: PropTypes.string.isRequired,
  alertKey: PropTypes.string.isRequired,
};

export default WebinarAnnouncement;