import authRequest from '../authRequest';
import NotePresenter from '../../presenters/NotePresenter';

const createNote = async (sessionUuid, noteTypeSlug) => {
    try {
        const response = await authRequest(`/sessions/${sessionUuid}/create_note`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ note_type_slug: noteTypeSlug }),
        });

        if (parseInt(response.status) !== 200) {
            throw new Error('Error creating note');
        }

        return new NotePresenter(response.data);
    } catch (error) {
        console.error('Error creating note:', error);
        throw error;
    }
};

export default createNote;
