import authRequest from '../authRequest';
import SessionPresenter from '../../presenters/SessionPresenter';

const fetchSessionsList = async (currentPage) => {
  try {
    const response = await authRequest(`/sessions/?page=${currentPage}`, { method: 'GET' });
    if (response.status === 200) {
      const sessions = response.data.sessions.map(session => new SessionPresenter(session));
      const pagination = {
        total_entries: response.data.meta.total_entries,
        total_pages: response.data.meta.total_pages,
        current_page: response.data.meta.current_page
      };
      return { sessions, pagination };
    } else {
      throw new Error('Fetching sessions failed');
    }
  } catch (error) {
    throw new Error('Fetching sessions failed');
  }
};

export default fetchSessionsList;
