import React, { useEffect, useRef } from 'react';
import createNote from '../../utils/session/createNote';
import authRequest from '../../utils/authRequest';
import { handleError } from '../../utils/errorHandling';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Space, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import deleteSession from '../../utils/session/deleteSession';
import NoteTypeButton from './NoteTypeButton';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const NoteTypeSelector = ({ sessionUuid, detailsPage = false, visible, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const screens = useBreakpoint();

  useEffect(() => {
    if (!visible) return;

    const handleNoteTypeClick = async (noteTypeId) => {
      try {
        const data = await createNote(sessionUuid, noteTypeId);
        navigate(`/sessions/`);
        window.location.reload();
        Modal.destroyAll();
        onClose();
      } catch (error) {
        displayErrorModal('Error', t('common.errors.general'));
      }
    };

    const displayErrorModal = (title, errMsg) => {
      Modal.error({
        title: title,
        content: errMsg,
      });
      console.error(title, errMsg);
    };

    const handleCancelClick = () => {
      Modal.confirm({
        title: t('note_type_selector.confirm_title'),
        content: t('note_type_selector.confirm_context'),
        okText: t('note_type_selector.confirm_ok_text'),
        okType: 'danger',
        cancelText: t('note_type_selector.confirm_no_text'),
        onOk: async () => {
          const success = await deleteSession(sessionUuid);
          if (success) {
            Modal.destroyAll();
            window.location.reload();
          }
        },
      });
    };

    // Utility function to render the buttons by section
    const renderNoteTypeButtons = (data, slugs) => (
      slugs.map((slug) =>
        data.filter(type => type.slug === slug).map((type) => (
          <NoteTypeButton key={type.slug} type={type} onClick={handleNoteTypeClick} />
        ))
      )
    );

    // Main content generation for the modal
    const noteTypeButtons = (data) => (
      <Space direction="vertical" style={{ width: '100%', display: 'flex', paddingTop: '15px' }}>
        <Divider plain orientation="right">{t('note_type_selector.note_type_sections.general_notes')}</Divider>
        {renderNoteTypeButtons(data, ['general_soap', 'first_visit'])}

        <Divider plain orientation="right">{t('note_type_selector.note_type_sections.specialized_visits')}</Divider>
        {renderNoteTypeButtons(data, ['specialist_visit', 'ob_gyn_visit', 'pediatric_visit', 'surgical_follow_up'])}

        <Divider plain orientation="right">{t('note_type_selector.note_type_sections.mental_health')}</Divider>
        {renderNoteTypeButtons(data, ['psychotherapy', 'psychiatric_evaluation'])}

        <Divider plain orientation="right">{t('note_type_selector.note_type_sections.hospital_care')}</Divider>
        {renderNoteTypeButtons(data, ['hospital_progress_note', 'emergency_urgent_care'])}

        <Divider plain orientation="right">{t('note_type_selector.note_type_sections.nutrition')}</Divider>
        {renderNoteTypeButtons(data, ['nutrition_consultation'])}

        <Divider plain orientation="right"></Divider>
        <div>
          {!detailsPage && (
            <Button
              block
              danger
              onClick={handleCancelClick}
              data-testid="note-type-button-cancel"
            >
              {t('note_type_selector.delete')}
            </Button>
          )}
        </div>
      </Space>
    );

    // API request and modal creation
    authRequest('/verticals/1/note_types')
      .then(response => {
        if (parseInt(response.status) === 200) {
          return response.data;
        } else {
          throw new Error('Error fetching note types');
        }
      })
      .then(data => {
        if (Array.isArray(data) && modalRef.current === null) {
          modalRef.current = Modal.info({
            title: t('note_type_selector.title'),
            content: noteTypeButtons(data),
            okButtonProps: { style: { display: 'none' } },
            closable: detailsPage,
            width: screens.xs ? '50vw' : 650,  // Adjust modal width for mobile
            onCancel: () => {
              modalRef.current = null;
              Modal.destroyAll();
              onClose();
            },
            icon: null,
          });
        }
      })
      .catch(error => {
        handleError(t('common.errors.general'));
        onClose();
      });
  }, [t, navigate, sessionUuid, detailsPage, visible, onClose]);

  return <div data-testid="note-type-selector" />;
};

export default NoteTypeSelector;