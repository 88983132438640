import { useEffect, useCallback } from 'react';
import beaconRequest from '../utils/beaconRequest';

export const usePreventRecordingAbandon = (isRecording, sessionUuid) => {
  const handleBeforeUnload = useCallback(
    (event) => {
      if (isRecording) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    [isRecording]
  );

  const handleUnload = useCallback(() => {
    if (isRecording && sessionUuid) {
      const path = `/sessions/${sessionUuid}/mark_abandoned_on_page_reload`;

      console.log(`handleUnload: calling beaconRequest with path=${path}`);
      const success = beaconRequest(path);

      if (!success) {
        console.error('handleUnload: beaconRequest failed to send beacon');
      }
    }
  }, [isRecording, sessionUuid]);

  const addPreventListener = useCallback(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
  }, [handleBeforeUnload, handleUnload]);

  const removePreventListener = useCallback(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('unload', handleUnload);
  }, [handleBeforeUnload, handleUnload]);

  useEffect(() => {
    if (isRecording) {
      addPreventListener();
    } else {
      removePreventListener();
    }

    return () => {
      removePreventListener();
    };
  }, [isRecording, addPreventListener, removePreventListener]);

  return {
    addPreventListener,
    removePreventListener,
  };
};