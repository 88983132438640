import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

function LegalDocument({ fileName }) {
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        const fetchMarkdown = async () => {
            try {
                const response = await fetch(`/legal/${fileName}`);
                const markdownText = await response.text();
                setMarkdown(markdownText);
            } catch (error) {
                console.error('Error fetching legal document:', error);
            }
        };

        fetchMarkdown();
    }, [fileName]);

    return (
        <div className="legal-document-container" style={{ maxWidth: '600px', fontSize: 'medium' }}>
            <div className="legal-document-content">
                <ReactMarkdown>{markdown}</ReactMarkdown>
            </div>
        </div>
    );
}

export default LegalDocument;
