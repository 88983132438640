import { useState, useCallback, useEffect } from 'react';
import { getDeviceInfo } from "../utils/getDeviceInfo";
import sendAudioData from '../utils/audioUpload';
import { saveAudioBlob, deleteAudioBlob } from '../utils/audioStorage';
import { handleError, handleWarning } from '../utils/errorHandling';
import startSession from '../utils/session/startSession';
import { useTranslation } from 'react-i18next';
import useWakeLock from './useWakeLock';
import useDetectSilence from './useDetectSilence';
import supportedBrowser from '../utils/supportedBrowser';
import { Bugsnag } from "../bugsnag";

// _initialChunksCount should be used only in tests, to simulate the number of chunks that have been recorded
export const useRecorder = (audioMimeType, _initialChunksCount = 0) => {
    // Each audio partial is 45 seconds long
    const PARTIAL_SIZE_IN_SEC = 45;
    const PARTIAL_SIZE_IN_MSEC = PARTIAL_SIZE_IN_SEC * 1000;
    const { t } = useTranslation();

    const [isRecording, setIsRecording] = useState(false);
    const [hasStarted, setHasStarted] = useState(false);
    const [hasPaused, setHasPaused] = useState(false);
    const [sessionUuid, setSessionUuid] = useState(null);
    const [recorder, setRecorder] = useState(null);
    const [totalChunks, setTotalChunks] = useState(_initialChunksCount);

    // Silence detection
    const [analyser, setAnalyser] = useState(null);
    const { problemType, problemStats } = useDetectSilence(analyser, isRecording, hasPaused);

    const deviceInfo = getDeviceInfo();
    const isSupportedBrowser = supportedBrowser();

    const { startWakeLock, stopWakeLock } = useWakeLock();

    const isSupportedMediaRecorder = () => {
        return navigator.mediaDevices && navigator.mediaDevices.getUserMedia && typeof MediaRecorder !== 'undefined';
    };

    useEffect(() => {
        return () => recorder?.stream.getTracks().forEach(track => track.stop());
    }, [recorder]);

    const requestAudioPermission = async (uuid) => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const options = { mimeType: audioMimeType };
            console.log(`MediaRecorder: using ${audioMimeType} format for audio.`);
            const mediaRecorder = new MediaRecorder(stream, options);
            let currentChunkIndex = 0;

            // context, source and analyser needed for silence detection
            const audioContext = new (window.AudioContext)();
            const audioSource = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();

            audioSource.connect(analyser);
            setAnalyser(analyser);

            mediaRecorder.ondataavailable = async (e) => {
                console.log(`ondataavailable: received ${e.data.size} bytes`);
                if (e.data.size > 0) {
                    const blob = e.data instanceof Blob ? e.data : new Blob([e.data], { type: audioMimeType });
                    const chunkData = { sessionUuid: uuid, chunkIndex: currentChunkIndex, blob: blob };

                    try {
                        console.log(`Saving chunkIndex=${currentChunkIndex}, sessionUuid=${uuid}`);
                        await saveAudioBlob(chunkData);
                    } catch (error) {
                        console.error('Error saving audio blob:', error);
                    }

                    const success = await sendAudioData(chunkData);
                    if (success) {
                        console.log(`Chunk index ${currentChunkIndex} uploaded successfully`);
                        console.log(`Deleting uploaded chunk index ${currentChunkIndex}`);
                        await deleteAudioBlob(uuid, currentChunkIndex);
                    } else {
                        console.log(`Failed to upload chunk index ${currentChunkIndex}`);
                    }

                    // Increment local chunk index after it has been used
                    currentChunkIndex += 1;
                    // Update total chunks count
                    console.log(`Setting totalChunks to ${currentChunkIndex}`);
                    setTotalChunks(currentChunkIndex);
                }
            };

            mediaRecorder.onstop = async () => {
                console.log('MediaRecorder: stopped');
                await stopWakeLock();
            };

            mediaRecorder.onpause = () => {
                console.log('MediaRecorder: paused');
                // Ensure any remaining data is flushed
                mediaRecorder.requestData();
            };

            mediaRecorder.onerror = (event) => {
                console.error('MediaRecorder error:', event.error);
                Bugsnag.notify(new Error(`mediaRecorder.onerror: ${JSON.stringify(event.error)}`));
                handleError(t('session_recorder.errors.access_device'));
            };

            setRecorder(mediaRecorder);
            await startWakeLock();
            console.log(`MediaRecorder: starting recording in chunks of ${PARTIAL_SIZE_IN_SEC} seconds`);
            mediaRecorder.start(PARTIAL_SIZE_IN_MSEC);
            setIsRecording(true);
            setHasStarted(true);
        } catch (err) {
            Bugsnag.notify(new Error(`requestAudioPermission error: ${JSON.stringify(err)}`));
            handleError(t('session_recorder.errors.access_device'));
        }
    };

    const toggleRecording = useCallback(async () => {
        if (!isSupportedBrowser || !isSupportedMediaRecorder()) {
            handleWarning(t('session_recorder.errors.unsupported_browser'));
            Bugsnag.notify(new Error(`Unsupported Browser: ${JSON.stringify(deviceInfo)}`));
            return;
        }

        if (!recorder && !hasStarted) {
            const newSessionUuid = await startSession();
            if (newSessionUuid) {
                setSessionUuid(newSessionUuid);
                await requestAudioPermission(newSessionUuid);
            } else {
                handleError(t('session_recorder.errors.start'));
            }
        } else if (recorder && recorder.state === 'recording') {
            recorder.pause();
            setIsRecording(false);
            setHasPaused(true);
        } else if (recorder && recorder.state === 'paused') {
            recorder.resume();
            setIsRecording(true);
            setHasPaused(false);
        }
    }, [recorder, hasStarted]);

    const endRecording = async () => {
        if (recorder) {
            recorder.stop();
            setIsRecording(false);
            setHasStarted(false);
            await stopWakeLock();
            // Reset total chunks count on full stop
            setTotalChunks(0);
        }
    };

    return {
        isRecording,
        hasStarted,
        hasPaused,
        toggleRecording,
        endRecording,
        sessionUuid,
        totalChunks,
        problemType,
        problemStats
    };
};
