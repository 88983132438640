import React from 'react';
import { Typography, Collapse, Grid } from 'antd';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const FAQ = () => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  return (
    <div>
      <Title
        level={3}
        style={{
          textAlign: 'center',
          marginTop: '50px',
          marginBottom: '16px',
          fontWeight: '300',
        }}
      >
        {t('faq.title')}
      </Title>
      <Collapse style={{ maxWidth: '800px', margin: '0 auto' }}>
        {t('faq.questions', { returnObjects: true }).map((faq, index) => (
          <Panel header={faq.question} key={index}>
            <p>{faq.answer}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQ;
