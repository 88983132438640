import React from 'react';
import { Layout, Typography, Row, Col, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  MessageOutlined,
  FolderOpenOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import FAQ from '../components/FAQ';
import HomeFeature from '../components/home/HomeFeature';

const { Content } = Layout;
const { Title } = Typography;
const { useBreakpoint } = Grid;

const Home = () => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const features = [
    {
      key: 'answers',
      icon: <MessageOutlined />,
      iconBackgroundColor: '#fffbe6',
      iconColor: '#faad14',
      link: '/queries/new',
    },
    {
      key: 'sessions',
      icon: <FileTextOutlined />,
      iconBackgroundColor: '#e6f7ff',
      iconColor: '#1890ff',
      link: '/sessions',
    },
    {
      key: 'cases',
      icon: <FolderOpenOutlined />,
      iconBackgroundColor: '#f6ffed',
      iconColor: '#52c41a',
      link: '/cases',
    },
  ];

  return (
    <Content className="page home-page">
      {screens.md && (
        <Row justify="center" style={{ marginBottom: '20px' }}>
          <Col>
            <Title level={2} style={{ fontWeight: '300', textAlign: 'center' }}>
              {t('home.title')}
            </Title>
          </Col>
        </Row>
      )}
      <Row gutter={[8, 8]}>
        {features.map((feature) => (
          <Col xs={24} sm={12} md={8} key={feature.key}>
            <HomeFeature feature={feature} />
          </Col>
        ))}
      </Row>
      <FAQ />
    </Content>
  );
};

export default Home;
