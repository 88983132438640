import React, { useState, useEffect } from 'react';
import { Form, Input, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import createQuery from '../../utils/query/createQuery';
import { handleError } from '../../utils/errorHandling';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

const QueryForm = ({ initialQuestion = '', onSubmit = null, shouldSubmit = true, previousQueryUuid = null, placeholder = null }) => {
  const [question, setQuestion] = useState(initialQuestion);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (initialQuestion) {
      setQuestion(initialQuestion);
      form.setFieldsValue({ question: initialQuestion });
    }

    if (shouldSubmit && initialQuestion) {
      setFormSubmitting(true);
    }
  }, [initialQuestion, form, shouldSubmit]);

  useEffect(() => {
    if (formSubmitting && question) {
      handleSubmit();
    }
  }, [formSubmitting, question]);

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const newQuery = await createQuery(question, previousQueryUuid, !!initialQuestion);
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate(`/queries/${newQuery.uuid}`);
      if (onSubmit) onSubmit?.();
    } catch (error) {
      if (error.name !== 'ValidationError') {
        handleError('Failed to create query. Please try again.');
      }
    } finally {
      setFormSubmitting(false);
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    setQuestion(e.target.value);
    form.setFieldsValue({ question: e.target.value });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{ question }}
    >
      <Form.Item
        name="question"
        rules={[
          {
            required: true,
            message: t('query_form.fields.enter_question'),
          },
        ]}
      >
        <div>
          <Input.TextArea
            value={question}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={ placeholder || t('query_new.fields.placeholder') }
            rows={3}
            style={{
              backgroundColor: 'var(--background-color)',
              borderColor: '#8c8c8c',
              borderRadius: '8px',
              padding: '10px',
              fontSize: '16px'
            }}
            data-testid="query-input"
          />
          <Paragraph type="secondary" style={{ marginTop: '8px', textAlign: 'right' }}>
            {t('query_form.fields.submit_hint')}
          </Paragraph>
        </div>
      </Form.Item>
    </Form>
  );
};

export default QueryForm;
