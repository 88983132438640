import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

const BenefitItem = ({ content }) => {
  const itemStyle = {
    display: 'flex',  // Align emoji and text in a row
    alignItems: 'flex-start',
  };

  const textStyle = {
    flex: 1,           // Allow the text to take up remaining space
    textAlign: 'center',
    fontSize: '15px',
  };

  return (
    <div style={itemStyle}>
      <Paragraph style={textStyle}>
        <ReactMarkdown components={{ p: ({ node, ...props }) => <p {...props} /> }}>
          {content}
        </ReactMarkdown>
      </Paragraph>
    </div>
  );
};

const FeatureBenefits = ({ featureKey }) => {
  const { t } = useTranslation();

  // Fetch the benefits array from the translation key
  const benefits = [
    t(`${featureKey}.fields.bullet1`),
    t(`${featureKey}.fields.bullet2`),
    t(`${featureKey}.fields.bullet3`),
  ];

  return (
    <Row gutter={[20, 20]}>
      {benefits.map((benefit, index) => (
        <Col xs={24} sm={8} md={8} key={index}>
          <BenefitItem content={benefit} />
        </Col>
      ))}
    </Row>
  );
};

export default FeatureBenefits;
