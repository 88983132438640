import authRequest from '../authRequest';
import SessionPresenter from '../../presenters/SessionPresenter';

const updateSession = async (uuid, userNotes) => {
  try {
    const response = await authRequest(`/sessions/${uuid}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ session: { user_notes: userNotes } }),
    });
    return new SessionPresenter(response.data.session);
  } catch (error) {
    console.error('Updating session failed:', error);
    throw error;
  }
};

export default updateSession;
