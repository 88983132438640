// src/utils/startSession.js
import authRequest from '../authRequest';
import { getDeviceInfo } from '../getDeviceInfo';

const startSession = async () => {
  const deviceInfo = getDeviceInfo();
  const formData = new FormData();
  formData.append('device_info', JSON.stringify(deviceInfo));

  try {
    const response = await authRequest('/sessions', {
      method: 'POST',
      body: formData
    });
    if (response.status === 200) {
      return response.data.uuid;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export default startSession;
