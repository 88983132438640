import React from 'react';
import { Empty, Button, Typography, Space, Card } from 'antd';
import {FileTextTwoTone, LockOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import FeatureBenefits from "../FeatureBenefits";
import SessionRecorder from "./SessionRecorder";

const { Title, Text } = Typography;

const cardStyle = {
  textAlign: 'center',
};

const spaceStyle = {
  width: '100%',
};

const SessionEmptyState = ({ showModal }) => {
  const { t } = useTranslation();

  return (
    <Card
      style={cardStyle}
      data-testid="case-empty-state-container"
    >
      <Space direction="vertical" size="large" style={spaceStyle}>
        <Empty
          image={<FileTextTwoTone style={{fontSize: '60px', marginBottom: '10px'}}/>}
          description={<Title level={3} style={{marginTop: '0px'}}>{t('session_empty_state.title')}</Title>}
        />
        <FeatureBenefits featureKey='session_empty_state'/>
        <div className='session-recorder'>
          <SessionRecorder showDemo={true}/>
        </div>
      </Space>
    </Card>
);
};

export default SessionEmptyState;
