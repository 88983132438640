import React, { useState, useEffect } from 'react';
import {Layout, Grid, Typography} from 'antd';
import ProtectedRoute from '../ProtectedRoute';
import SideNavigation from '../../components/SideNavigation';
import { useTranslation } from 'react-i18next';
import {LockOutlined, CalendarOutlined} from "@ant-design/icons";
import WebinarAnnouncement from '../announcements/WebinarAnnouncement';

const { useBreakpoint } = Grid;
const { Paragraph } = Typography;
const { Content, Footer } = Layout;

const ProtectedLayout = ({ children, hideSidenav = false }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const screens = useBreakpoint();
  const hideSidenavOnMobile = collapsed && hideSidenav;

  useEffect(() => {
    setCollapsed(!screens.md);
  }, [screens.md]);

  return (
    <ProtectedRoute>
      {hideSidenavOnMobile ? null : (
        <SideNavigation collapsed={collapsed} setCollapsed={setCollapsed} />
      )}
      <Layout
        className='layout'
        style={{
          marginLeft: hideSidenavOnMobile ? 0 : (collapsed ? 80 : 200),
          transition: 'margin 0.2s'
        }}
      >
        <WebinarAnnouncement
          icon={<CalendarOutlined style={{fontSize: '18px'}} />}
          alertKey="webinar-dec-17"
          mobileText="Webinar: IA en su Práctica"
          desktopText='Únase a nuestro próximo webinar: "IA en su Práctica - Webinar para Profesionales de Salud"'
          buttonLabel="Registrarse"
          buttonHref="https://bit.ly/itaca-webinar"
        />
        <Content>
          <div className="site-layout-content">{children}</div>
        </Content>
        <Footer style={{ textAlign: 'center', marginBottom: '10px' }}>
          <Paragraph type="secondary" style={{ marginBottom: '5px' }}>
            <LockOutlined className='iconSpacing' />
            {t('sessions.fields.security_note_encryption')}
          </Paragraph>
        </Footer>
      </Layout>
    </ProtectedRoute>
  );
};

export default ProtectedLayout;
