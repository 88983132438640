import React from 'react';
import { Button } from 'antd';
import { PauseOutlined, AudioFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function RecordingButton({ isRecording, hasStarted, toggleRecording }) {
    const { t } = useTranslation();
    const icon = isRecording ? <PauseOutlined className='iconSpacing' /> : <AudioFilled className='iconSpacing' />;

    function getButtonText() {
        if (isRecording) {
            return t('session_recorder.fields.pause');
        } else if (hasStarted) {
            return t('session_recorder.fields.resume');
        } else {
            return t('session_recorder.fields.start');
        }
    }

    const buttonText = getButtonText();

    return (
        <Button
            // hasStarted && !isRecording means that the button says "Continue recording"
            type= { hasStarted && !isRecording ? "default" : "primary"}
            shape="round"
            size="large"
            onClick={toggleRecording}
            data-testid='toggle-recording-button'
            style={{ minWidth: '250px' }}
        >
            {icon}{buttonText}
        </Button>
    );
}

export default RecordingButton;
