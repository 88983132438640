import authRequest from './authRequest';

const sendSupportRequest = async (subject, message) => {
  try {
    const response = await authRequest('/support_requests', {
      method: 'POST',
      data: {
        subject: subject,
        message: message,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Sending support request failed:', error);
    throw error;
  }
};

export default sendSupportRequest;