import React from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Grid } from 'antd';

const { Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const NoteTypeButton = ({ type, onClick }) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  return (
    <div style={{ width: '100%' }}>
      <Button
        block
        onClick={() => onClick(type.slug)}
        data-testid={`note-type-button-${type.slug}`}
        style={{ padding: '12px', textAlign: 'left', height: 'auto' }}
      >
        <Text strong style={{ display: 'block', fontSize: '16px' }}>
          {type.emoji} {t(`note_type_selector.note_types.${type.slug}.title`)}
        </Text>
        { screens.sm &&
          <Paragraph type="secondary" style={{ fontSize: '12px', margin: 0 }}>
            {t(`note_type_selector.note_types.${type.slug}.description`)}
          </Paragraph>
        }
      </Button>
    </div>
  );
};

export default NoteTypeButton;
